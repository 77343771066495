<template>
  <div class="Global_Card ce-login">
    <router-link
      class="Login-LoginActiv"
      to="/lk/login"
    >
      {{ $t('login_link') }}
    </router-link>
    <router-link
      class="Login-RegistrationActiv"
      to="/lk/registration"
    >
      {{ $t('registration_link') }}
    </router-link>
    <form
      class="ce-login-form"
      @submit.prevent.stop="singin"
    >
      <div class="ce-login-form-line">
        <ce-text-field
          id="login"
          v-model.trim="login"
          :placeholder="$t('lp_login_placeholder')"
          :label="$t('lp_login_label')"
          :autocomplete="'on'"
          @change="validateInputLogin"
          @focus="changeReady"
          @keyup="enterUserId"
        >
          <template #append-input>
            <div class="ce-login-form-icon">
              <i class="Icon_Info" />
              <div class="Form-HelpModal">
                <div class="help_input_header">
                  Enter user id
                </div>
                <div class="help_input_content">
                  example: <span>111111111111</span>
                </div>
              </div>
            </div>
          </template>
        </ce-text-field>
      </div><br>

      <div class="Form-Line Form-Line_Top">
        <ce-text-field
          id="password"
          v-model="password"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          :label="$t('lp_password_label')"
          :placeholder="$t('lp_password_placeholder')"
          :autocomplete="'on'"
          @change="validateInputPassword"
          @focus="changeReady"
        >
          <template #append-input>
            <span @click="showPassword = !showPassword"><i class="Icon_Eye" /></span>
          </template>
        </ce-text-field>
      </div><br>
      <div class="Form-Line_Save Global_Input SaveData">
        <input
          id="saveData"
          v-model="savingDataUser"
          type="checkbox"
        >
        <label for="saveData" />
        <p class="SaveData_margin">
          {{ $t('lp_save_credentials') }}
        </p>
      </div><br>
      <div class="Login-PEMKey">
        <label
          id="LabelForFile"
          for="file"
          class="Login-LabelButton"
        >
          <svg
            class="Login-LabelButton_img"
            width="18"
            height="22"
            viewBox="0 0 18 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 1H3C1.89543 1 1 1.89543 1 3V19C1 20.1046 1.89543 21 3 21H15C16.1046 21 17 20.1046 17 19V8L10 1Z"
              stroke="#BFBFBF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10 1V8H17"
              stroke="#BFBFBF"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <br>
          {{ $t('confirm_insert_pem') }}
        </label>
        <input
          id="file"
          name="file"
          accept=".pem"
          type="file"
          class="Login-Input_File"
          @change="insertPem"
        >

        <label
          id="LabelForText"
          for="text"
          class="Login-LabelButton"
          @click="ShowHideText"
        ><img
          class="Login-LabelButton_img"
          src="../assets/images/EnterFile.svg"
          alt="close"
        ><br>{{ $t('confirm_enter_pem') }}</label>
        <textarea
          id="text"
          v-model="text"
          name="text"
          class="Login-TextArea"
          @change="validatePemKeyText"
        />
      </div>
      <input
        class="Global_Button Global_Button_FontSize_S Global_Button_Color_White Global_Button_Rounding_Main"
        type="submit"
        :value="$t('lp_login_btn')"
      >
    </form>
  </div>
</template>

<script>
import { NativeBiometric } from 'capacitor-native-biometric'
import { mapActions } from 'vuex'
import CeTextField from '@/components/common/CeTextField.vue'

export default {
  name: 'Login',
  components: { CeTextField },
  metaInfo: {
    title: 'Login'
  },
  data () {
    return {
      login: '',
      password: '',
      file: '',
      text: '',
      savingDataUser: false,
      showPassword: false
    }
  },
  async created () {
    // const login = this.$GetCookie('Login')
    // const pass = this.$GetCookie('Password')
    const login = localStorage.getItem('lg')
    const pass = localStorage.getItem('ps')
    // console.log({ login, pass, isAndroidApp })
    if (login && pass) {
      this.login = login
      this.password = pass
      this.savingDataUser = true
    }
    if (login && isAndroidApp) {
      const { key, password } = await this.checkShowBiometric({
        reason: this.$t('biometric_confirm_reason'),
        title: this.$t('biometric_confirm_title')
      })
      if (!key) return
      this.login = login
      this.password = password
      this.text = key
      this.savingDataUser = true
      this.singin()
    }
  },
  methods: {
    ...mapActions(['checkShowBiometric']),
    enterUserId () {
      // todo
      // this.login = this.$UserId(this.login)
    },
    showHidePassword () {
      let inputPass = document.getElementById('password')
      if (inputPass.getAttribute('type') === 'password') {
        inputPass?.setAttribute('type', 'text')
      } else {
        inputPass?.setAttribute('type', 'password')
      }
    },
    validateInputLogin (event) {
      this.login = this.$EnteringUser(this.login)
      if (!this.$NewValid({
        typeValidation: 'login',
        dataForValid: {
          login: this.login
        }
      })) {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'enteredDataNotUserId'),
          type: 'error'
        })
      } else {
        event.target.classList.add('SuccessInput')
        event.target.classList.remove('ErrorInput')
      }
    },
    validateInputPassword (event) {
      if (!this.$NewValid({
        typeValidation: 'password',
        dataForValid: {
          password: this.password
        }
      })) {
        event.target.classList.remove('SuccessInput')
        event.target.classList.add('ErrorInput')
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'passwordDoesNotMatchRequirements'),
          type: 'error'
        })
      } else {
        event.target.classList.add('SuccessInput')
        event.target.classList.remove('ErrorInput')
      }
    },
    validatePemKey () {
      document.getElementById('LabelForText').classList.remove('InputFileSuccess')
      document.getElementById('LabelForText').classList.remove('InputFileError')
      if (this.$NewValid({
        typeValidation: 'PemKey',
        dataForValid: {
          key: this.text
        }
      })) {
        document.getElementById('LabelForText').classList.remove('InputFileError')
        document.getElementById('LabelForFile').classList.add('InputFileSuccess')
      } else {
        document.getElementById('LabelForText').classList.remove('InputFileSuccess')
        document.getElementById('LabelForFile').classList.add('InputFileError')
      }
    },
    validatePemKeyText () {
      document.getElementById('LabelForFile').classList.remove('InputFileSuccess')
      document.getElementById('LabelForFile').classList.remove('InputFileError')
      if (this.$NewValid({
        typeValidation: 'PemKey',
        dataForValid: {
          key: this.text
        }
      })) {
        document.getElementById('LabelForText').classList.remove('InputFileError')
        document.getElementById('LabelForText').classList.add('InputFileSuccess')
      } else {
        document.getElementById('LabelForText').classList.remove('InputFileSuccess')
        document.getElementById('LabelForText').classList.add('InputFileError')
      }
    },
    insertPem (event) {
      document.getElementById('LabelForFile').classList.remove('InputFileSuccess')
      document.getElementById('LabelForFile').classList.remove('InputFileError')
      let files = event.target
      let reader = new FileReader()
      reader.onload = (f) => {
        this.file = reader.result
        this.text = reader.result
        this.validatePemKey()
      }
      reader.readAsText(files.files[0])
    },
    changeReady (event) {
      event.target.classList.remove('ErrorInput')
      event.target.classList.remove('SuccessInput')
    },
    ShowHideText () {
      const element = document.getElementById('text')
      if (element.style.display === 'none') {
        element.style.display = 'block'
      } else {
        element.style.display = 'none'
      }
    },
    singin () {
      if (document.querySelector('.ErrorInput') || document.querySelector('InputFileError')) {
        console.log(this.$selectMessageText('En', 'error'))
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'NotAllDataEntered'),
          type: 'error'
        })
      } else if (this.login && this.password && (this.file || this.text)) {
        if (this.savingDataUser) {
          // this.$SetCookie('Login', this.login)
          // this.$SetCookie('Password', this.password)
          localStorage.setItem('lg', this.login)
          localStorage.setItem('ps', this.password)
        } else {
          this.$DelCookie('Login')
          this.$DelCookie('Password')
        }
        this.$store.commit('SetLoaderFlag', true)
        this.$store.dispatch('singIn', { login: this.login, password: this.password, key: this.file ? this.file : this.text })
          .then(async () => {
            try {
              const result = await NativeBiometric.isAvailable()
              if (result.isAvailable) {
                if (!localStorage.getItem('useBiometric')) {
                  this.$store.commit('setBiometricData', {
                      password: this.password,
                      key: this.file ? this.file : this.text
                  })
                  this.$store.commit('setShowUseBiometricPopup', true)
                }
              }
            } catch (err) {
              console.log(err)
            }
          })
          .catch(() => {
            this.$notify({
              title: this.$t('error_title'),
              text: this.$selectMessageText('En', 'loginFailed'),
              type: 'error'
            })
          })
          .finally(() => {
            this.$store.commit('SetLoaderFlag', false)
          })
      } else {
        this.$notify({
          title: this.$t('error_title'),
          text: this.$selectMessageText('En', 'notAllData'),
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="stylus">
@import '../styles/variable.styl'
form
  padding 40px 0px 0px 0px
.Login
  &-PEMKey
    display: flex;
    justify-content: space-between;
    flex-wrap wrap
    margin-bottom 30px
  &-LabelButton
    font-family 'Geometria', sans-serif
    font-size: 12px
    color #aFaFaF
    background: #f2f2f2
    padding: 10px
    flex-basis: 49.5%;
    cursor pointer
    &_img
      margin-bottom 6px
  &-LoginActiv
    font-family 'Montserrat', sans-serif
    text-decoration: none
    font-style: normal;
    font-weight: bold;
    font-size: 20px
    color #ffc600
    margin-right 10px
    border-top 3px solid #fff
    border-bottom 3px solid #FFC600
    &:hover
      font-family 'Montserrat', sans-serif
      font-size: 20px
      color #ffc600
  &-RegistrationActiv
    font-family 'Montserrat', sans-serif
    text-decoration: none
    font-style: normal;
    font-weight: bold;
    margin-left 10px
    font-size: 20px
    color #bFbFbF
    &:hover
      font-family 'Montserrat', sans-serif
      font-size: 20px
      color #ffc600
  &-TextArea
    display none
    width: 100%;
    height: 100px;
    margin-top: 10px;
    resize: none;
  &-Input
    &_File
      display none
.InputFileSuccess
  background-color Success
  color: #fff;
  & > svg > path
    stroke white
.InputFileError
  background-color Alert
  color: #fff;
  & > svg > path
    stroke white
</style>
